<template>
  <div>
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item,index) in userRoleList" :key="index">
        <div class="cardItem">
          <img :src="orgbg" class="orgbg-img">
          <div class="role">角色</div>
          <p class="cardName">{{item.roleName}}</p>
          <p class="cardDes">{{item.deptName}}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
  name: "swiperItem",
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  props:['userRoleList'],
  data () {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        // spaceBetween: 10,
        on: {
          slideChange:(e) => {
            this.activeIndex = e.activeIndex
          },
        }
      },
      orgbg: require('@/assets/img/mine/orgbg.png'),
      activeIndex: 0,
      clickIndex: 0
    }
  },
  watch:{
    activeIndex(val){
      this.$emit('swiperClick', val)
    }
  },
  mounted () {
  },
  methods: {
    
  }
}
</script>

<style scoped lang="less">
.swiper-slide {
  width: calc(~"90% + 10px"); //每一块宽度
  // margin-right: 10px;
  &:nth-last-child(1){
    margin-right: 10%;
  }
}

.title {
  margin-bottom: 15px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.orgbg-img{

}
.cardItem {
  position: relative;
  img {
    width: 100%;
  }
  .express-num {
    position: absolute;
    left: 0.24rem;
    top: 0.32rem;
    z-index: 100;
    font-size: 0.24rem;
    color: #3F7C53;
    background: #fff;
    width: 0.85rem;
    height: 0.4rem;
    line-height: 0.4rem;
    border-radius: 0.2rem;
    text-align: center;
  }
  .cardName {
    font-size: 0.4rem;
    color: #ffffff;
    position: absolute;
    left: 0.7rem;
    top: 1rem;
  }
  .role{
    font-size: 0.26rem;
    color: #fff;
    position: absolute;
    left: 0.7rem;
    top: 0.6rem;
  }
  .cardDes {
    font-size: 0.4rem;
    color: #ffffff;
    position: absolute;
    left: 0.7rem;
    top: 2.1rem;
  }
}
</style>