<template>
  <div class="organization">
    <div class="title-bg">
      <deptswiper-item :userRoleList="userRoleList" @swiperClick="swiperClick"></deptswiper-item>
    </div>

    <div class="content">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="content-item"
              v-for="(item, index) in organizationList"
              :key="index">
            <div class="content-item__header flex">
              <div>{{item.stationName}}</div>
              <div>{{item.createTime}}</div>
            </div>
            <div class="content-item__content">站长：{{item.masterName}}</div>
            <div class="content-item__footer">{{item.stationLocate}}</div>
          </div>
        </van-list>
    </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getMyOrg, getDeptRoleList } from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "@/utils/util";
import deptswiperItem from '@/components/deptswiperItem'
export default {
  components: {
    [Toast.name]: Toast,
    deptswiperItem
  },
  data () {
    return {
      organizationList: [],
      headerIcon: require('@/assets/img/mine/headerIcon.png'),
      org_item_icon: require('@/assets/img/mine/org_item_icon.png'),
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
      deptNo: '',
      userRoleList: []
    }
  },
  mounted () {
    uwStatisticAction('/organization','我的组织')
    this.togetDeptRoleList()
  },
  methods:{
    swiperClick(index){
      this.deptNo = this.userRoleList[index].deptNo
      this.togetMyOrg()
    },
    togetDeptRoleList() {
      getDeptRoleList({
        token: common.getItem('wtToken')
      }).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.userRoleList = res.data.data
            this.deptNo = this.userRoleList[0].deptNo
            this.togetMyOrg()
          }
        }
      })
    },
    togetMyOrg(){
      getMyOrg({
        deptNo: this.deptNo,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.loading = false;
            this.finished = !res.data.data.pageModel.hasNextPage;
            if (this.pageIndex == 1) {
              this.organizationList = res.data.data.pageModel.list;
            } else {
              this.organizationList = this.organizationList.concat(res.data.data.pageModel.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }
          }
        }
      })
    },
    onRefresh() {
      this.isLoading = false
      this.organizationList = []
      this.pageIndex = 1
      this.togetMyOrg()
    },
    onLoad () {
      
    },
  }
}
</script>

<style lang="less" scoped>
.organization {
  background: #f5f5f5;
  min-height: 100%;
  // padding: 0.3rem;
  font-size: 0.32rem;
  padding-bottom: 1rem;
  .title-bg {
    position: relative;
    padding-top: 0.3rem;
    .title-info {
      position: absolute;
      width: 6.9rem;
      height: 2.14rem;
      border-radius: 0.16rem;
      background: #fff;
      left: 50%;
      top: 40%;
      transform: translateX(-50%);
      padding: 0.38rem 0.3rem;
      align-items: center;
    }
    .header-icon{
      width: 1.36rem;
      height: 1.36rem;
    }
    .left64{
        margin-left: 0.64rem !important;
      }
    .title-info__item{
      margin-left: 0.32rem;
      div {
        &:nth-child(1) {
          color: #999;
          font-size: 0.24rem;
        }
        &:nth-child(2){
          font-size: 0.3rem;
          font-weight: bold;
          margin-top: 0.16rem;
        }
      }
    }
  }
  .flex {
    display: flex;
  }
  .title {
    margin: 0.2rem 0.1rem;
  }
  .content {
    background: #f7f8f9;
    padding: 0.2rem 0.3rem 0;
    border-radius: 0.2rem;
    .content-item {
      background: #fff;
      padding: 0.36rem 0.24rem 0.36rem 0.4rem;
      margin-top: 0.24rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      border-radius: 0.16rem;
      &::after{
        content: '';
        background: url("../../assets/img/mine/org_item_icon.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1.58rem;
        height: 1.2rem;
      }
      .content-item__header {
        justify-content: space-between;
        align-items: flex-start;
        font-size: 0.34rem;
        font-weight: bold;
        position: relative;
        div:nth-child(1) {
          &::before{
            content: '';
            width: 0.08rem;
            height: 0.34rem;
            background:#3F7C53;
            position: absolute;
            left: -0.2rem;
            top: 0.1rem;
          }
        }
        div:nth-child(2) {
          font-size: 0.26rem;
          font-weight: normal;
          width: 1.6rem;
          flex: 0 0 1.6rem;
          margin-top: 0.08rem;
        }
      }
      // .content-item__header,
      .content-item__content,
      .content-item__footer {
        color: #999999;
        font-size: 0.3rem;
        margin-top: 0.12rem;
      }
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .content-item__footer{
        word-wrap: break-word;
        word-break: normal;
      }
    }
  }
}
</style>

